@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background-color: #f4f5f6;
    --color: #000;
    --secondary: #0000000a;
    --muted: #0000000f;
    --muted-foreground: #6b7280;
    --accent: #00000014;
    --accent-foreground: #111827;
    --background: #f4f5f6;
  }

  .dark {
    --secondary: #ffffff0a;
    --muted: #ffffff0f;
    --muted-foreground: #94a3b8;
    --accent: #ffffff14;
    --accent-foreground: #e2e8f0;
    --background-color: #0f172a;
    --color: #ffffff;
    --background: #0f172a;
  }
}

.grid-pattern::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to right, #48433d 2px, transparent 2px),
    linear-gradient(to bottom, #48433d 2px, transparent 2px);
  opacity: 1.6%;
  z-index: -1;
}
